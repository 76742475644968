<template>
    <RecordViewWrapper>
        <CardToolbox>
            <SearchHeaderStyle>
                <sdPageHeader title="Operational Costs">+
                    <template #buttons>
                        <sdButton @click="toggelOperationModal" class="btn-add_new" size="default" type="primary" key="1">
                            <a to="#">+ Add New</a>
                        </sdButton>
                    </template>
                </sdPageHeader>
            </SearchHeaderStyle>
        </CardToolbox>
        <Main>
            <a-row :gutter="15">
                <a-col class="w-100" :md="24">
                    <sdCards title="Filters ">
                        <a-form :model="filterForm" layout="vertical">
                            <a-row :gutter="25">
                                <a-col :md="6" :xs="6">
                                    <a-form-item label="Expense" name="bankOperationTypeId">
                                        <a-select v-model:value="filterForm.bankOperationTypeId" placeholder="Expense">
                                            <a-select-option v-for="(
                                expenseType, index
                              ) in ExpenseTypeApi" :key="index" :value="expenseType.id">
                                                {{ expenseType.name }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :xs="6">
                                    <a-form-item label="Partner" name="partnerId">
                                        <a-select v-if="partnerApi.length" v-model:value="filterForm.partnerId"
                                            placeholder="Please choose Owner" show-search style="width: 100%"
                                            :options="partnerData" :not-found-content="partnerFetching ? undefined : null
                                                " :filter-option="false"
                                            @search="(value) => searchPartner(value, filterForm.operationSupport == 1, filterForm.operationSupport == 2)">
                                            <template v-if="partnerFetching" #notFoundContent>
                                                <a-spin size="small" />
                                            </template>
                                        </a-select>
                                        <a-select v-else v-model:value="filterForm.partnerId"
                                            placeholder="Please choose Owner">
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item name="date" label="Date From">
                                        <a-date-picker :picker="'start'" v-model:value="filterForm.dateStart"
                                            :style="{ width: '100%' }" format="DD/MM/YYYY" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6">
                                    <a-form-item name="date" label="Date To">
                                        <a-date-picker :picker="'start'" v-model:value="filterForm.dateEnd"
                                            :style="{ width: '100%' }" format="DD/MM/YYYY" />
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6" :xs="6">
                                    <a-form-item label="Receipt type" name="receiptType">
                                        <a-select v-model:value="filterForm.receiptType" placeholder="Receipt type"
                                            style="width: 100%">
                                            <a-select-option :value="1">Final invoice</a-select-option>
                                            <a-select-option :value="2">Performa invoice</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                </a-col>
                                <a-col :span="6" class="button-container">
                                    <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary"
                                        @click="onFilter()">
                                        <span>Filter</span>
                                    </a-button>
                                </a-col>
                                <a-col :span="6" class="button-container">
                                    <a-button class="ant-btn-lg" @click="clearFilters()" danger>
                                        <span>Clear Filters</span>
                                    </a-button>
                                </a-col>
                            </a-row>
                        </a-form>
                    </sdCards>
                </a-col>
            </a-row>
            <a-row :gutter="15">
                <a-col class="w-100" :md="24">
                    <sdCards headless>
                        <div>
                            <TableWrapper class="table-data-view table-responsive">
                                <a-table @change="onHandleTableChange" :pagination="pagination" :dataSource="dataSource"
                                    :loading="isLoading" :columns="columns">
                                    <template #files="{ record }">
                                        <div>
                                            <a-button v-for="file in record.files" style="margin-right: 4px;" :key="file.id"
                                                :title="file.title" @click="() => downloadFile(file.id)">
                                                <sdFeatherIcons type="file" size="14" />
                                            </a-button>
                                        </div>
                                    </template>
                                    <template #footer>
                                        <div class="table-footer">
                                            <div class="table-footer-right">
                                                <div class="label">
                                                    Total : {{ totalNotPaginated }}
                                                </div>
                                            </div>
                                            <div class="table-footer-left">
                                                <div class="label">
                                                    Table Total : {{ totalPaginated }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </a-table>
                            </TableWrapper>
                        </div>
                    </sdCards>
                </a-col>
            </a-row>
            <Operation v-if="showOperationModal" :expenseOperation="selectedExpenseOperation" :pagination="pagination"
                @cancel="toggelOperationModal" @validate="handleValidate">
            </Operation>
        </Main>
    </RecordViewWrapper>
</template>
<script>
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { DownOutlined } from '@ant-design/icons-vue';
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, defineAsyncComponent, getCurrentInstance } from "vue";
import { useFileDownload } from '@/composable/useFileDownload';
import usePartnerSearch from '@/composable/usePartnerSearch';
import moment from "moment";


const Operation = defineAsyncComponent(() => import("./expenseOperations/Operation.vue"));
const GenericFilter = defineAsyncComponent(() => import("@/components/businessComponents/GenericFilter.vue"));

const dateFormat = "YYYY/MM/DD";
const dateFormatTime = "HH:mm";


const columns = [
    {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: true,
    },
    {
        title: "Expense type",
        dataIndex: "expenseOperation.expenseTypeName",
        key: "expenseOperation.expenseTypeName",
    },
    {
        title: "Partner ",
        dataIndex: "expenseOperation.partenaireName",
        key: "Partner",
    },
    {
        title: "Expense File",
        dataIndex: "files",
        key: "files",
        slots: { customRender: "files" }, // Use a custom render for this column
    },
    {
        title: "Purchase Count ",
        dataIndex: "itemsCount",
        key: "purchaseNumber",
    },
    {
        title: "Amount",
        dataIndex: "expenseOperation.amount",
        key: "expenseOperation.amount",
    },
    {
        title: "Validity",
        dataIndex: "isValid",
        key: "isValid",
    },
    {
        title: "Actions",
        dataIndex: "action",
        key: "action",
        width: "90px",
    },
];

const expenseOperations = {
    name: "expenseOperations",
    components: {
        Main,
        TableWrapper,
        FormValidationWrap,
        VerticalFormStyleWrap,
        RecordViewWrapper,
        CardToolbox,
        SearchHeaderStyle,
        DownOutlined,
        Operation,
        GenericFilter
    },
    setup() {
        const { state, dispatch } = useStore();
        const searchValue = ref("");
        const selectedExpenseOperation = ref(null);
        const AccountApi = computed(() => state.AccountApi.data);
        const ExpenseTypeApi = computed(
            () => state.ExpenseTypeApi.data
        );

        const PaymentMethodApi = computed(() => state.PaymentMethodApi.data);
        const { downloadFile } = useFileDownload();

        var expenseOperationPagination = computed(() => state.ExpenseOperationApi.data);
        var ExpenseOperationApi = computed(() => state.ExpenseOperationApi.data.data);
        var totalPaginated = computed(() => state.ExpenseOperationApi.totalPaginated);
        var totalNotPaginated = computed(() => state.ExpenseOperationApi.totalNotPaginated);
        const isLoading = computed(() => state.ExpenseOperationApi.loading);
        const partnerApi = computed(() => state.partnerApi.data);
        const filters = ref([
            { label: "Expense Account", name: 'ExpenseAccountId', type: 'apiList', value: '', options: AccountApi },
        ]);

        const appContext = getCurrentInstance().appContext;
        const userHasPermission = appContext.config.globalProperties.$userHasPermission;
        var loading = false;
        const localState = reactive({
            visible: false,
            editVisible: false,
            modalType: "primary",
            url: null,
        });

        onMounted(() => {
            dispatch("paymentMethodInfo");
            dispatch("partnerInfo");
            dispatch("accountInfo");
            dispatch("expenseTypeInfo", true);
            dispatch("expenseOperationGetTotal", pagination.value);
            dispatch("expenseOperationGetPagedData", pagination.value);
        });

        const sorted = ref({
            columnKey: "",
            order: "",
        });

        const pagination = computed(() => {
            const defaultPagination = {
                current: 1,
                filters: filterForm,
                pageSize: 10,
                OrderBy: sorted.value.columnKey,
                searchValue: "",
                orderBy: "",
                sorter: {
                    order: sorted.value.order == "descend" ? "desc" : "asc",
                },
            };

            if (expenseOperationPagination.value && expenseOperationPagination.value.succeeded) {
                return {
                    ...defaultPagination,
                    current: expenseOperationPagination.value.pageNumber,
                    total: searchValue.value
                        ? expenseOperationPagination.value.recordsFiltered
                        : expenseOperationPagination.value.recordsTotal,
                };
            }

            return defaultPagination;
        });


        const onHandleSearch = () => {
            sorted.value.order = "ascend";
            dispatch("expenseOperationGetPagedData", {
                ...pagination.value,
                current: 1,
                OrderBy: sorted.value.order,
                searchValue: searchValue.value,
            });
        };

        const showModal = () => {
            localState.visible = true;
        };

        const handleDelete = (id) => {
            const confirm = window.confirm("Are you sure delete this?");
            if (confirm) {
                dispatch("expenseOperationApiDataDelete", { id, pagination: pagination.value });
            }
            return false;
        };

        const dataSource = computed(() =>
            ExpenseOperationApi.value && ExpenseOperationApi.value.length
                ? ExpenseOperationApi.value.map((expenseOperation, key) => ({
                    key: key + 1,
                    expenseOperation,
                    date: moment(expenseOperation.date).format(dateFormat),
                    isValid: expenseOperation.isValid ? "Valid" : "Need validation",
                    itemsCount: expenseOperation.itemIds.length ?? 0,
                    files: expenseOperation.files?.map((file) => ({
                        title: file.fileName,
                        id: file.id,
                    })),
                    action: (
                        <div class="table-actions">
                            {userHasPermission('Permissions.Expense.CanValidate') && !expenseOperation.isValid ?
                                <a-button
                                    class="edit"
                                    onClick={() => handleValidate(expenseOperation)}
                                >
                                    <sdFeatherIcons type="check-circle" size={14} />
                                </a-button> : null}
                            &nbsp;&nbsp;&nbsp;
                            <a-button
                                class="edit"
                                onClick={() => handleEdit(expenseOperation)}
                            >
                                <sdFeatherIcons type="edit" size={14} />
                            </a-button>
                            &nbsp;&nbsp;&nbsp;
                            <a-button
                                class="delete"
                                onClick={() => handleDelete(expenseOperation.id)}
                            >
                                <sdFeatherIcons type="trash-2" size={14} />
                            </a-button>

                        </div>
                    ),
                }))
                : []
        );


        const showOperationModal = ref(false);
        const toggelOperationModal = () => {
            dispatch("emptyFile");
            selectedExpenseOperation.value = null;
            showOperationModal.value = !showOperationModal.value;
        }

        const handleEdit = (expenseOperation) => {
            dispatch("emptyFile");
            selectedExpenseOperation.value = expenseOperation;
            showOperationModal.value = !showOperationModal.value;
        }
        const handleValidate = (expenseOperation) => {
            dispatch("expenseOperationValidation", expenseOperation);
        }

        //// filters 
        var filterForm = reactive({
            dateFrom: "",
            bankOperationTypeId: "",
            receiptType: "",
            dateTo: "",
            partnerId: "",
        });

        const onFilter = () => {
            sorted.value = {
                columnKey: "date",
                order: "descend",
            };

            pagination.value = {
                ...pagination,
                current: 1,
                orderBy: "date",
                pageSize: 10,
                sorter: {
                    order: sorted.value.order == "descend" ? "desc" : "asc",
                },
                filters: filterForm.value,
            }
            dispatch("expenseOperationGetTotal", pagination.value);
            dispatch("expenseOperationGetPagedData", pagination.value);
        }
        const clearFilters = () => {
            filterForm.dateFrom = "";
            filterForm.bankOperationTypeId = "";
            filterForm.receiptType = "";
            filterForm.dateTo = "";
            filterForm.partnerId = "";
            onFilter();
        }

        const onHandleTableChange = (pagination, filters, sorter) => {
            sorted.value = {
                columnKey: sorter.columnKey ?? "",
                order: sorter.order,
            };

            pagination.value = {
                ...pagination,
                sorter: {
                    order: sorted.value.order == "descend" ? "desc" : "asc",
                },
                orderBy: sorted.value.columnKey,
                filters: filterForm.value,
            }

            dispatch("expenseOperationGetTotal", pagination);
            dispatch("expenseOperationGetPagedData", pagination);
        };
        const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
            filterForm.partnerId
        );
        return {
            isLoading,
            handleDelete,
            dataSource,
            columns,
            localState,
            showModal,
            onHandleSearch,
            loading,
            onHandleTableChange,
            searchValue,
            AccountApi,
            dateFormat,
            dateFormatTime,
            partnerApi,
            ExpenseTypeApi,
            showOperationModal,
            toggelOperationModal,
            selectedExpenseOperation,
            PaymentMethodApi,
            pagination,
            filters,
            filterForm,
            onFilter,
            clearFilters,
            partnerData,
            searchPartner,
            partnerFetching,
            totalPaginated,
            totalNotPaginated,
            downloadFile,
            handleValidate
        };
    },
};

export default expenseOperations;
</script>
<style scoped>
.table-footer {
    display: flex;
    justify-content: space-between;
    text-align: right;
    font-size: 15px;
    font-weight: bold;
}

.table-footer .table-footer-right {
    text-align: left;

}

.ant-btn-lg {
    height: 38px;
    width: 100%;
}

.button-container {
    place-self: center;
}
</style>